<template>
    <section class="photos-section-wrapper">
        <headerOfSection :title="$t('works.photosTitle')" :isWhite="true">
            <span v-html="$t('works.photosText')"></span>
        </headerOfSection>
        <div class="photos-wrapper">
            <div class="row-wrapper">
                <parallaxContainer className="para-colaboration"> 
                    <parallaxItem :isActive="isAnimation" :factor="0.6" :scrollX="true" :scrollY="false">
                        <div class="row-container">
                            <div v-for="(e, i) of photosRow1" :key="i" class="cel-wrapper">
                                <img :src="photoUrl + e.instagram_photo_id + '/' + e.standard_resolution">
                            </div>
                        </div>
                    </parallaxItem>
                </parallaxContainer>
            </div>
            <div class="row-wrapper">
                <parallaxContainer className="para-colaboration"> 
                    <parallaxItem :isActive="isAnimation" :factor="-0.6" :scrollX="true" :scrollY="false">
                        <div class="row-container">
                            <div v-for="(e, i) of photosRow2" :key="i" class="cel-wrapper">
                                <img :src="photoUrl + e.instagram_photo_id + '/' + e.standard_resolution">
                            </div>
                        </div>
                    </parallaxItem>
                </parallaxContainer>
            </div>
        </div>
    </section>
</template>
<script>

import parallaxContainer from './../../components/parallax-container';
import parallaxItem from './../../components/parallax-item';
import headerOfSection from './../../components/section-header';

export default {
    components: {
        parallaxContainer, 
        parallaxItem, 
        headerOfSection
    },
    data() {
        return {
            isAnimation: false,
            resources:[],
            photosRow1:[],
            photosRow2:[],
            photoUrl: "/backend/instagram/"
        }
    },
    created() {
        window.addEventListener('scroll', this.scrollHandler);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },
    methods: {
        scrollHandler() {
            const el = document.getElementById('works-photos-section');

            const windowTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset

            this.isAnimation = el.offsetTop - window.innerHeight <= windowTop && (el.offsetTop + el.offsetHeight) >= windowTop;
        },
        getPhotos() {
            this.$http({ url: 'photos', method: 'GET'})
                .then((res) => {
                this.resources = this.shuffle(res.data.data);
                for(let i = 0; i < this.resources.length; i++)
                {
                    if(i % 2 == 0) {
                        this.photosRow1.push(this.resources[i]);
                    } else {
                        this.photosRow2.push(this.resources[i]);
                    }
                }
                this.photosRow1.reverse();
                this.photosRow2.reverse();
            });
        },
        shuffle(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        }
    },
  mounted() {
    this.getPhotos();
  }
}
</script>
<style lang="scss" scoped>
@import './../../scss/app';

.photos-section-wrapper {
    position: relative;
    background: #F0F0F0;
}

.row-container {
    display: flex;
}

.row-wrapper {
    position: relative;

    &:first-child {
        right:50%;
    }
}

.cel-wrapper {
    height: 20vh;
    padding: 5px;

    img {
        height: 100%;
        width:auto;
    }
}
</style>