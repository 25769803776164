<template>
  <div class="wrrapper">
    <cookies-popup />
    <navigation :isWhite="isWhiteNav" />
    <div
      v-if="isHeroBg"
      :style="
        'filter: blur(' +
          blurVal +
          'px);-webkit-filter: blur(' +
          blurVal +
          'px)'
      "
      class="image-bg gradient-overlay"
      style="background-image: url(/images/works/startup-3267505_1280.jpg);"
    ></div>
    <div class="content-page-wrapper">
      <header-page id="works-header-section" />
      <portfolio id="works-potfolio-section" />
      <section id="works-action-section" class="action-wrapper">
        <action
          :questionText="$t('works.youTubePageQuestion')"
          :linkText="$t('works.youTubePageLink')"
          linkHref="/youtube"
        />
      </section>
      <photos id="works-photos-section" />
      <footerPage />
    </div>
  </div>
</template>

<script>
import navigation from "./../components/navigation";
import headerPage from "./../sections/works/header";
import action from "./../components/action-block";
import portfolio from "./../sections/works/portfolio";
import photos from "./../sections/works/photos";
import footerPage from "./../sections/footer";
import cookiesPopup from './../components/cookies-popup'

export default {
  data() {
    return {
      isWhiteNav: true,
      navElement: null,
      isHeroBg: true,

      mobileWidth: 793,
      tabletWidth: 992,
      blurVal: 0,
      sectionsSettings: [
        {
          domId: "works-header-section",
          isNavWhite: true,
          isHeroBgShown: true,
          isBlureHeaderBg: true,
        },
        {
          domId: "works-potfolio-section",
          isNavWhite: false,
          isHeroBgShown: false,
          isBlureHeaderBg: false,
        },
        {
          domId: "works-action-section",
          isNavWhite: true,
          isHeroBgShown: false,
          isBlureHeaderBg: false,
        },
        {
          domId: "works-photos-section",
          isNavWhite: false,
          isHeroBgShown: false,
          isBlureHeaderBg: false,
        },
      ],
    };
  },
  metaInfo: {
    title: "D&D Promotion - Works",
  },
  components: {
    navigation,
    action,
    headerPage,
    portfolio,
    photos,
    footerPage,
    cookiesPopup
  },
  created() {
    window.addEventListener("scroll", this.worksScrollOverHandler);
  },
  destroyed() {
    window.removeEventListener("scroll", this.worksScrollOverHandler);
  },
  methods: {
    worksScrollOverHandler() {
      const windowTop = this.getWindowTop();

      for (let i = 0; i < this.sectionsSettings.length; i++) {
        const section = this.sectionsSettings[i];
        const el = document.getElementById(section.domId);

        if (
          el.offsetTop <= windowTop &&
          el.offsetTop + el.offsetHeight >= windowTop
        ) {
          this.isHeroBg = section.isHeroBgShown;
          this.isWhiteNav = section.isNavWhite;

          if (section.isBlureHeaderBg) {
            this.blurVal = windowTop / 50;
          }
        }
      }

      if (this.isMobile() || this.isTablet()) {
        this.isWhiteNav = true;
      }
    },
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
    getWindowTop() {
      return window.pageXOffset === undefined
        ? document.documentElement.scrollTop
        : window.pageYOffset;
    },
  },
  mounted() {
    this.navElement = document.querySelector(".nav");
    if (this.isMobile() || this.isTablet()) {
      this.isWhiteNav = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.action-wrapper {
  position: relative;
  background-color: #1d1f1f;
}

.gradient-overlay {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4c3ed9;
    background: -moz-linear-gradient(60deg, #9136a5, #da6161, transparent 70%);
    background: -webkit-linear-gradient(
      60deg,
      #9136a5,
      #da6161,
      transparent 70%
    );
    background: linear-gradient(60deg, #9136a5, #da6161, transparent 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9136A5', endColorstr='#DA6161',GradientType=0 );
  }
}
</style>
