<template>
  <section v-if="isSenaKanaEnabled || isPooBearEnabled" class="portfolio-wrapper">
    <div class="large-side-row">
      <div class="large-block">
        <router-link
          v-if="!isSenaKanaEnabled && isPooBearEnabled"
          to="poo-bear"
          class="portfolio-block"
        >
          <div
            class="portfolio-block-background"
            style="background-image: url('images/works/810f7bc8230f5f228863468ba73d091a3e07e8b3_400x400.jpg')"
          ></div>
          <div class="portfolio-block-inner">
            <span class="number">01.</span>
            <h3 class="title">{{ $t("works.portfolio.pooBear.title") }}</h3>
            <p class="description">
              {{ $t("works.portfolio.pooBear.description") }}
            </p>
            <p class="additional-descrition">
              {{ $t("works.portfolio.pooBear.addDescription") }}
            </p>
          </div>
        </router-link>
        <router-link
          to="/sena-kana"
          class="portfolio-block"
          v-if="isSenaKanaEnabled"
        >
          <div
            class="portfolio-block-background"
            style="background-image: url('images/works/UK201703140007.jpg')"
          ></div>
          <div class="portfolio-block-inner">
            <span class="number">01.</span>
            <h3 class="title">{{ $t("works.portfolio.senaKana.title") }}</h3>
            <p class="description">
              {{ $t("works.portfolio.senaKana.description") }}
            </p>
            <p class="additional-descrition">
              {{ $t("works.portfolio.senaKana.addDescription") }}
            </p>
          </div>
        </router-link>
      </div>
      <div class="side-blocks">
        <router-link v-if="isSenaKanaEnabled && isPooBearEnabled" to="poo-bear" class="portfolio-block">
          <div
            class="portfolio-block-background"
            style="background-image: url('images/works/810f7bc8230f5f228863468ba73d091a3e07e8b3_400x400.jpg')"
          ></div>
          <div class="portfolio-block-inner">
            <span class="number">02.</span>
            <h3 class="title">
              {{ $t("works.portfolio.pooBear.title") }}
            </h3>
            <p class="description">{{ $t("works.portfolio.pooBear.description") }}</p>
          </div>
        </router-link>

        <div v-if="!isSenaKanaEnabled || !isPooBearEnabled" class="portfolio-block">
          <div
            class="portfolio-block-background"
            style="background-image: url('images/works/rsz_1rsz_1d&d_v_logo_full_gold.jpg')"
          ></div>
          <div class="portfolio-block-inner">
            <span class="number">02.</span>
            <h3 class="title">{{ $t("works.portfolio.empty.title") }}</h3>
            <p class="description">{{ $t("works.portfolio.empty.description") }}</p>
          </div>
        </div>

        <div v-if="isSenaKanaEnabled || isPooBearEnabled" class="portfolio-block">
          <div
            class="portfolio-block-background"
            style="background-image: url('images/works/rsz_1rsz_1d&d_v_logo_full_gold.jpg')"
          ></div>
          <div class="portfolio-block-inner">
            <span class="number">03.</span>
            <h3 class="title">{{ $t("works.portfolio.empty.title") }}</h3>
            <p class="description">{{ $t("works.portfolio.empty.description") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      mobileWidth: 793,
      tabletWidth: 992,
      isSenaKanaEnabled: false,
      isPooBearEnabled: false
    };
  },
  mounted() {
    this.$http({ url: 'setting/6', method: 'GET'})
      .then((res) => {
          this.isSenaKanaEnabled = +res.data.data.value;
      });

    this.$http({ url: 'setting/7', method: 'GET'})
      .then((res) => {
          this.isPooBearEnabled = +res.data.data.value;
      });
  },
  methods: {
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../scss/app";
$height: 80vh;
$path: 0.5vh;
$duration: 500ms;

.portfolio-wrapper {
  background: #f0f0f0;
  padding: 10vw 10vw 10vw;
}

.large-block {
  width: 65%;
  height: inherit;
  padding-right: $path;
}

.large-side-row {
  display: flex;
  height: $height;
}

.portfolio-block {
  display: block;
  text-decoration: none;
  position: relative;
  padding: 20px;
  height: inherit;
  background: #191a1a;
  cursor: pointer;
  overflow: hidden;
}

.portfolio-block-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  @include scale(1);
  @include transition(all $duration);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(25, 26, 26, 0);
    @include transition(all $duration);
  }
}

.portfolio-block-inner {
  position: relative;
  color: #dce6e6;
  .number {
    font-size: 2vw;
  }

  .title {
    padding-left: 20px;
    padding-top: $height / 2;
    font-size: 5vw;
    @include opacity(0);
    @include transition(all $duration);
    @include translateY(-10px);
  }

  .description {
    padding-left: 20px;
    @include opacity(0);
    @include font-size(27px);
    @include transition(all $duration);
    @include translateY(10px);
  }

  .additional-descrition {
    padding-left: 20px;
    @include opacity(0);
    padding-top: 50px;
    font-family: $family-light;
    @include transition(all $duration);
    @include translateY(10px);
  }
}

.side-blocks {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .portfolio-block {
    height: ($height - $path) / 2;

    .portfolio-block-inner {
      .title {
        font-size: 3vw;
        padding-top: ($height - $path) / 4.5;
      }

      .description {
        padding-left: 20px;
        @include font-size(22px);
      }
    }
  }
}

.portfolio-block:hover {
  .portfolio-block-background {
    @include scale(1.1);
  }

  .portfolio-block-background:after {
    background: rgba(25, 26, 26, 0.9);
  }

  .portfolio-block-inner {
    .title {
      @include opacity(1);
      @include translateY(0);
    }

    .description {
      @include opacity(1);
      @include translateY(0);
    }

    .additional-descrition {
      @include opacity(1);
      @include translateY(0);
    }
  }
}

@media (max-width: 992px) {
  .large-side-row {
    flex-direction: column;
    height: auto;
  }

  .large-block {
    width: 100%;
    padding-right: 0;
    padding-bottom: $path;

    .title {
      padding-top: 250px;
    }
  }

  .side-blocks {
    width: 100%;
    flex-direction: row;

    .portfolio-block:first-child {
      width: 50%;
      margin-right: $path / 2;
    }

    .portfolio-block:last-child {
      width: 50%;
      margin-left: $path / 2;
    }

    .title {
      padding-top: 150px;
    }
  }

  .portfolio-block-background:after {
    background: #191a1ae6;
    background: -moz-linear-gradient(60deg, #191a1ae6, transparent 90%);
    background: -webkit-linear-gradient(60deg, #191a1ae6, transparent 90%);
    background: linear-gradient(60deg, #191a1ae6, transparent 90%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#191a1ae6', endColorstr='#DA6161',GradientType=0 );
  }

  .portfolio-block-inner {
    .number {
      font-size: 4vw;
    }

    .title {
      @include opacity(1);
      @include translateY(0);
      a {
        color: #dce6e6;
        text-decoration: none;
      }
      img {
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }

    .description {
      @include opacity(1);
      @include translateY(0);
    }

    .additional-descrition {
      @include opacity(1);
      @include translateY(0);
    }
  }
}

@media (max-width: 576px) {
  .side-blocks {
    width: 100%;
    flex-direction: column;

    .portfolio-block:first-child {
      width: 100%;
      margin-bottom: $path;
    }

    .portfolio-block:last-child {
      width: 100%;
      margin-left: 0;
    }
  }

  .portfolio-block-inner {
    .title {
      font-size: 8vw !important;
      padding-top: 120px !important;
    }

    .number {
      font-size: 8vw;
    }

    .description {
      font-size: 4vw !important;
    }

    .additional-descrition {
      display: none;
      font-size: 3vw !important;
      padding-top: 10px;
    }
  }

  .portfolio-block {
    height: 322px !important;
  }
}
</style>
